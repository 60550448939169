




















































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterUser from '@/components/filters/FilterUser.vue'
import {User} from '@/model/resource/User'
import {UserCollection} from '@/model/collection/UserCollection'
import {ListUserSchema} from '@/schema/resource/User/ListUserSchema'
import {ExportUserSchema} from '@/schema/resource/User/ExportUserSchema'

@Component({
  components: {FilterToggle, FilterUser},
})
export default class ListUserView extends Mixins(MixinRouteMatch) {
  schema = new ListUserSchema()
  collection = new UserCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: User) {
    this.$nav.pushByName('editUser', item.$id)
  }

  async removeItem(item: User) {
    await this.$dialog.remove(item)
    await item.removeUser()
    await this.collection.queryAsPage()
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new UserCollection().clearFilters().addFilter(params)

    await coll.listExportUser()
    this.$xlsx.downloadFromSchema(coll.items, new ExportUserSchema())
  }
}
